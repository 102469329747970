<template lang="pug">
.content-step2
  loading-logo(v-if="loading")
  template(v-else)
    om-select#listName(
      v-model="listId"
      :options="lists"
      :label="$t('integrationFlow.integrationModal.general.listHelper', { type: 'HubSpot' })"
      :placeholder="$t('integrationFlow.integrationModal.selectAList')"
      :error="isListInvalidByAlert"
      @input="onListChange"
    )
    #listName.error-msg.mt-3(v-if="isListInvalidByAlert" key="error-message")
      span.text-danger(v-html="$t('integrationFlow.integrationModal.general.invalidList')")
</template>
<script>
  import { HubSpotIntegrationService } from '@/services/integrations/hubSpotIntegrationService';
  import validationByAlert from '@/mixins/integration/validationByAlert';
  import trackStep2Mixin from '@/mixins/integration/trackStep2';

  export default {
    mixins: [validationByAlert, trackStep2Mixin],
    props: {
      settings: {
        type: Object,
        required: true,
      },

      validations: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        step2Fields: ['listId'],
        listId: null,
        lists: [],
        hasError: false,
        loading: true,
      };
    },

    watch: {
      listId(option) {
        this.updateListSetting(option.key);
      },
    },

    async mounted() {
      const hubSpotIntegrationService = new HubSpotIntegrationService();
      const { lists, fields } = await hubSpotIntegrationService.fetchListAndFields(
        this.$apollo,
        this.settings,
      );

      this.loading = false;

      this.updateFieldsSetting(fields);

      if (!lists.length) {
        this.updateListSetting(null);
        return;
      }

      this.lists = lists;

      const key = this.settings.convertedSettings.listId;

      this.listId = key ? { key } : null;

      this.storeInitialStep2State();
    },

    methods: {
      updateListSetting(value) {
        const settings = { ...this.settings };
        settings.convertedSettings.listId = value;
        this.$emit('update:settings', settings);
        this.emitIsModified();
      },

      updateFieldsSetting(value) {
        const settings = { ...this.settings };
        settings.fields = value;
        this.$emit('update:settings', settings);
        this.emitIsModified();
      },
    },
  };
</script>
